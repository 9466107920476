import { render, staticRenderFns } from "./companyEdit.vue?vue&type=template&id=4e28df58&scoped=true&"
import script from "./companyEdit.vue?vue&type=script&lang=ts&"
export * from "./companyEdit.vue?vue&type=script&lang=ts&"
import style0 from "./companyEdit.vue?vue&type=style&index=0&id=4e28df58&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e28df58",
  null
  
)

export default component.exports